/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import trentHeadshot from "assets/images/trentHeadshot.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }} bgColor="#191919">
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={trentHeadshot} alt="Trent Hall" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" mb={1}>
                <MKTypography variant="h3" color="gold" mx={5} verticalAlign="top">
                  Trent Hall
                  <br /> President, CEO
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text">
                  Trent has over 30 years experience in the motorcycle industry. He took his family
                  business with one location and two manufacturers, to 8 locations with over 40
                  manufacturers and over $60 million in revenue.
                  <br />
                  <br /> Trent started his career at 14 years old by sweeping the floor in the back
                  of his parents&apos; dealership. At the age of 21 he left the dealerships to
                  become a Fighter Pilot for the US Navy flying the F-14 Tomcat. After serving for 8
                  years, gaining combat experience, and countless hours of flight time, Trent moved
                  on to become a commercial airline pilot. As of today, Trent has 14,000+ hours of
                  accident free flying.
                  <br />
                  <br /> In 2005, Trent took over the family dealership which at the time only
                  carried Yamaha and Suzuki. He tripled the sales by 2007. In 2008, he opened his
                  second store just as the crash of 2008 was occurring. When others were exiting the
                  industry, Trent started acquiring other dealerships. He was able to expand from
                  one store to eight while growing revenue 10x.
                  <br />
                  <br /> Trent has won numerous awards and trips for his success as a dealer. He has
                  been appointed to multiple dealership councils and is familiar with all of the
                  executives from the OEs.
                  <br />
                  <br /> He has used his experience and success to consult with other dealerships.
                  In one particular engagement, Trent worked with a dealership principle with over
                  $600,000 in credit card debt at the beginning of the consult. In less than a year
                  the debt was reduced to 5 figures and in less than 2 was eliminated.
                  <br />
                  <br /> Trent&apos;s philosophy is based on Return on Investment. Investment can
                  mean the obvious example, but it can also mean time, exposure to risk, and general
                  state of mind. This philosophy knows how to run lean, but also takes advantage of
                  opportunities that present themselves.
                  <br />
                  <br /> Put Trent to work for you and your dealership and let the results speak for
                  themselves.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
