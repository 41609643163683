/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import trentHeadshot from "assets/images/trentHeadshot.jpg";
import ryanHeadshot from "assets/images/ryanHeadshot.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      bgColor="#191919"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      my={-1}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="gold" textGradient>
              Our Team
            </MKTypography>
            <MKTypography variant="body2" color="gold">
              Experienced and driven team ready to tackle your toughest problems.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={trentHeadshot}
                name="Trent Hall"
                position={{ color: "gold", label: "President, CEO" }}
                description="Business professional with an eye towards minimizing costs while maximizing revenue"
                action={{
                  type: "internal",
                  route: "/people/trent",
                  color: "info",
                  label: "read more",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={ryanHeadshot}
                name="Ryan Walsh"
                position={{ color: "gold", label: "CTO" }}
                description="Technology expert with experience that spans many different fields and technology stacks"
                action={{
                  type: "internal",
                  route: "/people/ryan",
                  color: "info",
                  label: "read more",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
