/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// router link
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function HorizontalTeamCard({ image, name, position, description, action }) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <MKBox width="100%" pt={2} pb={2} px={2} bgColor="black" borderRadius="10%">
            <MKBox
              component="img"
              src={image}
              alt={name}
              width="100%"
              borderRadius="md"
              shadow="lg"
              bgColor="black"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <MKBox
            pt={{ xs: 1, lg: 2 }}
            pb={2}
            pr={4}
            pl={{ xs: 4, lg: 1 }}
            lineHeight={1}
            bgColor="black"
            borderRadius="5%"
          >
            <MKTypography variant="h5" color="gold" textGradient>
              {name}
            </MKTypography>
            <MKTypography variant="h6" color={position.color} mb={1}>
              {position.label}
            </MKTypography>
            <MKTypography variant="body2" color="gold">
              {description}
            </MKTypography>
            {action && (
              <MKBox width="50%" mt={4} mx="auto">
                {action.type === "external" ? (
                  <MKButton
                    component={MuiLink}
                    href={action.route}
                    target="_blank"
                    rel="noreferrer"
                    color="gold"
                    size="small"
                    fullWidth
                  >
                    {action.label}
                  </MKButton>
                ) : (
                  <MKButton
                    component={Link}
                    to={action.route}
                    variant="outlined"
                    color="gold"
                    size="small"
                    fullWidth
                  >
                    {action.label}
                  </MKButton>
                )}
              </MKBox>
            )}
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "gold",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;
