/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="money_off"
                    title="Eliminate 100% of Fees"
                    description="There are no gimmicks, our system offers ZERO FEES"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="redeem"
                    title="No Cost Terminals"
                    description="We provide terminals at NO COST if used for 12 months"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="person_add_alt_1"
                    title="Customer Retention"
                    description="We love our customers and do what is necessary to show it"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="bolt"
                    title="Seamless Integration"
                    description="Our system requires no additional hardware or software besides a credit card terminal. We can have you and your company up and running in no time."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={{ xs: 5, md: 0 }}>
              <FilledInfoCard
                icon=""
                title=""
                description="We look forward to working with you and start saving you money ASAP.
                Let us schedule a call to go over our system and help you keep more of your money."
                action={{
                  type: "internal",
                  route: "/contact-us",
                  color: "gold",
                  label: "Find Out More",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
