/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="follow_the_signs"
                    title="Prepare For What's Coming Next"
                    description="Our team has weathered countless boom and bust cycles over the years.
                    Let us help you prepare for the next business cycle"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="dangerous"
                    title="Minimize Expenses"
                    description="Sometimes the tried and true need to be tweaked.
                    This is the way we've always done it might not be the most efficient way in these times"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    color="gold"
                    icon="trending_up"
                    title="Maximize Revenue"
                    description="Return on investment is our motto.
                    We want to help you squeeze every last percentage out of your dealership"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    color="gold"
                    icon="sports_motorsports"
                    title="Inventory Management"
                    description="Many dealerships have gotten into trouble by not correctly managing
                    inventory and letting manufacturers dictate what they have in their warehouse"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={{ xs: 5, md: 0 }}>
              <FilledInfoCard
                icon=""
                title=""
                description="Capture V.I.'s team of management experts have multiple fields of expertise and will put all of that to work for you. Led by Trent Hall, he will use over 30 years experience in dealerships to get the most out of your dealership. Trent took 1 store with 4 million in revenue and turned it into 8 stores with over 60 million in revenue. Our other team members also have years of experience running successful businesses. With our connections, expertise, and domain knowledge we will make your dealership more efficient."
                action={{
                  type: "internal",
                  route: "/contact-us",
                  color: "gold",
                  label: "Find Out More",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
