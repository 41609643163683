import amplitude from "amplitude-js";

export const initAmplitude = () => {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
    domain: process.env.REACT_APP_AMPLITUDE_DOMAIN,
    includeReferrer: true,
    includeUtm: true,
  });
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
