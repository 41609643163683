/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={50}
              suffix="+"
              title="Years of Experience"
              description="From business to technology, we have you covered"
              color="gold"
              textGradient
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider
              light
              orientation="vertical"
              sx={{ display: { xs: "none", md: "block" }, ml: 0 }}
            />
            <DefaultCounterCard
              count={10}
              suffix="M+"
              title="Money Saved"
              description="Providing you every opportunity to hold onto your money"
              color="gold"
              textGradient
            />
            <Divider
              light
              orientation="vertical"
              sx={{ display: { xs: "none", md: "block" }, ml: 0 }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={1}
              suffix="B+"
              title="Revenue Generated"
              description="Finding avenues and oppurtunities for new revenue streams"
              color="gold"
              textGradient
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
