/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="public"
                    title="Experience"
                    description="Our team has decades of experience tackling the hardest technology problems. No matter how involved, we are prepared to break it down and provide a solution."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    color="gold"
                    icon="apps"
                    title="Industry Focus"
                    description="We will help you get the most out of your Dealer Management System, specializing in Lightspeed EVO™"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="payments"
                    title="360˚ Offerings"
                    description="We are able to provide complete solutions and processes for employees to maximize those solutions. But, we can also provide processes for current infrastructure or integrate with legacy systems to address specific pain points."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    color="gold"
                    icon="3p"
                    title="Efficiency"
                    description="It is important to minimize downtime as much as possible so there is no interruption to your business. We understand this and work with you to create a plan that works best for your company."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={{ xs: 5, md: 0 }}>
              <FilledInfoCard
                icon=""
                title=""
                description="The technology team at Capture V.I. is ready to tackle your toughest challenges. Our team is led by Ryan Walsh who has decades of technology and consulting experience. He's spearheaded projects for large multinational corporations but also has worked with and at smaller institutions and understands balancing cost with efficiency. We look forward to working with you."
                action={{
                  type: "internal",
                  route: "/contact-us",
                  color: "gold",
                  label: "Find Out More",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
