/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import BusinessAreas from "pages/Presentation/sections/BusinessAreas";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/backgrounds/mainpage-logo.svg";

// Analytics
import { setAmplitudeUserId, sendAmplitudeData } from "utilities/amplitude";

function Presentation() {
  setAmplitudeUserId();
  sendAmplitudeData("PAGE_LOAD_MAIN");
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        minHeight="70vh"
        width="100%"
        bgColor="#000000"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" />
        </Container>
      </MKBox>
      <Card
        sx={{
          backgroundColor: "#191919",
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <BusinessAreas />
        {/* <Information /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6} bgColor="black">
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
