/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Services from "pages/LandingPages/Services";

// Business Areas
import CCProcessing from "pages/LandingPages/CCProcessing";
import MA from "pages/LandingPages/MA";
import BusinessConsulting from "pages/LandingPages/BusinessConsulting";
import TechnologyConsulting from "pages/LandingPages/TechnologyConsulting";

// People profiles
import Trent from "pages/LandingPages/People/Trent";
import Ryan from "pages/LandingPages/People/Ryan";

const routes = [
  {
    name: "services",
    icon: <Icon>category</Icon>,
    route: "/services",
    component: <Services />,
    dropdown: true,
    key: 12,
    collapse: [
      {
        name: "financial",
        dropdown: true,
        collapse: [
          {
            name: "Credit Card Processing",
            route: "/services/financial/ccp",
            component: <CCProcessing />,
            key: 1,
          },
          {
            name: "Mergers & Acquisitions",
            route: "/services/financial/ma",
            component: <MA />,
            key: 3,
          },
        ],
      },
      {
        name: "consulting",
        dropdown: true,
        collapse: [
          {
            name: "business",
            route: "/services/consulting/business",
            component: <BusinessConsulting />,
            key: 4,
          },
          {
            name: "technology",
            route: "/services/consulting/technology",
            component: <TechnologyConsulting />,
            key: 5,
          },
        ],
      },
    ],
  },
  {
    name: "about us",
    icon: <Icon>manage</Icon>,
    route: "/about-us",
    component: <AboutUs />,
    dropdown: true,
    key: 6,
    collapse: [
      {
        name: "People",
        dropdown: true,
        collapse: [
          {
            name: "trent hall",
            route: "/people/trent",
            component: <Trent />,
            key: 7,
          },
          {
            name: "ryan walsh",
            route: "/people/ryan",
            component: <Ryan />,
            key: 8,
          },
        ],
      },
    ],
  },
  {
    name: "contact us",
    icon: <Icon>contact_phone</Icon>,
    route: "/contact-us",
    component: <ContactUs />,
    key: 11,
  },
];

export default routes;
