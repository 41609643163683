/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import ryanHeadshot from "assets/images/ryanHeadshot.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }} bgColor="#191919">
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={ryanHeadshot} alt="Ryan Walsh" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" mb={1}>
                <MKTypography variant="h3" color="gold" mx={5} verticalAlign="top">
                  Ryan Walsh
                  <br /> CTO
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text">
                  Ryan has over 2 decades of technology experience and consulting. He started out
                  programming in middle school as a hobby. He then took his hobby to the next level
                  by pursuing a Electrical and Computer Engineering degree at Carnegie Mellon. Upon
                  graduating, Ryan pursued a job in technology consulting where he gained experience
                  in different fields including healthcare, technology, hospitality, and
                  motorsports.
                  <br />
                  <br /> Having worked in various fields, Ryan has the expertise, as well as the
                  detailed knowledge, to analyze current setups, develop a solution, and implement
                  said solution to provide technology transformations that allow for lower cost,
                  lower maintenence, better user experience, and providing value-added features to
                  maximize productivity.
                  <br />
                  <br /> Within the healthcare industry, Ryan took a outdated internal communication
                  system and replaced it with a state of the art communication platform. This
                  allowed employees to communicate documents more quickly and securely between
                  locations. The new platform was also much more flexible allowing for new uses to
                  be developed internally which further streamlined processes.
                  <br />
                  <br /> Ryan is also responsible for leading a team that radically transformed the
                  booking, check-in, and room entry technologies and procedures for a major
                  hospitality provider. He led the design of a completely custom solution that
                  integrated with certain legacy systems which could not be altered and were rigid
                  in their constraints. The end result was a process where customers could book
                  rooms through a new phone application, skip the check-in counter upon arrival, and
                  receive a digital key on their phone which would allow them to unlock the physical
                  door to their room. It also provided the company the ability to offer services and
                  upgrades to their customers that gained more views and subsequent purchase since
                  they were presented through the app to the customer.
                  <br />
                  <br /> Ryan also has experience within the motorsports industries. He was brought
                  in to the family motorsports business and tasked with revamping the handling of
                  inventory especially with respect to older and harder to move models. Ryan
                  developed an internal system which replaced a mistake prone email system for
                  handling the transfer of vehicles between 8 stores and 2 warehouses. He also
                  developed an automated system for finding older units and making sure they were on
                  the floor for customers to see and not locked in the corner of a warehouse.
                  <br />
                  <br /> Ryan&apos;s philosophy is to provide solutions that are robust and solve
                  the problem at hand. Solutions are designed to be flexible as the world moves at a
                  rapid pace, but not overbuilding and having that cost outweigh the benefits.
                  <br />
                  <br /> Let Ryan help you with your toughest technology problems and you&apos;ll
                  wonder why you didn&apos;t do it sooner.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
