/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

function MA() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="query_stats"
                    title="Discover Strengths"
                    description="Our team of experts have decades of experience in the motorsports industry. We will analyze your business and position
                    it to maximize its selling point"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="gold"
                    icon="security"
                    title="Privacy"
                    description="We keep your business your business. Discretion is a tenet of our business. All potential parties are fully vetted by our team and proper NDAs are enacted where necessary"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    color="gold"
                    icon="connect_without_contact"
                    title="Industry Connections"
                    description="Our experience in the industry helps provide the best potential buyers the fastest.
                    We will identify the best potential buyers and engage them quickly to minimize any impact on your business from a protracted selling process."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    color="gold"
                    icon="add_business"
                    title="Expand Your Business"
                    description="We will help you identify and acquire other businesses that will grow your company's reach, services, and profit."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={{ xs: 5, md: 0 }}>
              <FilledInfoCard
                icon=""
                title=""
                description="Why use a broker who has never owned their own dealership? We have bought and sold over 20 powersports dealerships ourselves. There is more to the valuation of your dealership than what can be found in financials. We take a broad approach that includes financials but also market potential and value of your brands. Our team has over 30 years of varied experience in M&A that will maximize every ounce of your dealership. Give us the opportunity to amaze you."
                action={{
                  type: "internal",
                  route: "/contact-us",
                  color: "gold",
                  label: "Find Out More",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default MA;
