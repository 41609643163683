/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// images
import ccpimage from "assets/images/ccp-lock.svg";
import maimage from "assets/images/ma-puzzle.jpg";
import bcimage from "assets/images/bc-handshake.jpg";
import tcimage from "assets/images/tc-lightbulb.jpg";

import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function BusinessAreas() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={3} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={ccpimage}
              title="Zero Cost Credit Card Processing"
              description="Make your credit card transactions work for you rather than against you"
              action={{
                type: "internal",
                route: "/services/financial/ccp",
                color: "gold",
                label: "find out more",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={bcimage}
              title="Business Consulting"
              description="Identify underlying issues and provide solutions to fix them."
              action={{
                type: "internal",
                route: "/services/consulting/business",
                color: "gold",
                label: "find out more",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mx: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={tcimage}
              title="Technology Consulting"
              description="Services ranging from Lightspeed EVO optimizations, process creation, and IT transformation"
              action={{
                type: "internal",
                route: "/services/consulting/technology",
                color: "gold",
                label: "find out more",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mx: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={maimage}
              title="Mergers & Acquisitions"
              description="We help you discover new revenue streams, strategic acquistions, and synergistic opportunities"
              action={{
                type: "internal",
                route: "/services/financial/ma",
                color: "gold",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BusinessAreas;
